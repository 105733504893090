import { Features, useIsABVariant } from '@finn/ua-featureflags';
import { GenericVehicleDetails, parseFilterValues } from '@finn/ua-vehicle';
import { CTA } from '@finn/ui-components';
import { NumericHeadingSizes, ProductCard, Slider } from '@finn/ui-modules';
import { useIsMobile } from '@finn/ui-utils';
import { makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';

import { useProductListItems } from '~/modules/products/list/api';
import { Filters } from '~/types/filter';
import { CTAData } from '~/types/UIModules';

const useStyles = makeStyles((theme) => ({
  productItem: {
    width: 280,
    [theme.breakpoints.up('sm')]: {
      width: 360,
    },
    '&.moreCars': {
      width: 164,
      [theme.breakpoints.up('sm')]: {
        width: 270,
      },
    },
  },
  buttonWrapper: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(7.5),
    },
  },
}));

interface IProps {
  title?: string;
  headingStyle?: NumericHeadingSizes;
  filter?: Filters;
  cta?: CTAData;
  baseURL?: string;
  urlParams?: string;
  isForIframe?: boolean;
  isInPLP?: boolean;
  hidePrice?: boolean;
  forceB2BPrice?: boolean;
  vehicles?: GenericVehicleDetails[];
}

const ProductSlider: React.FunctionComponent<IProps> = ({
  title,
  headingStyle,
  filter = {},
  cta,
  baseURL,
  urlParams,
  isForIframe = false,
  isInPLP = false,
  hidePrice = false,
  forceB2BPrice = false,
  vehicles,
}) => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const isMoreCarsExp = useIsABVariant(Features.ExpMoreCarsInSlider);
  const filters = useMemo(() => parseFilterValues(filter), [filter]);
  const { products } = useProductListItems(
    {
      filters,
      zipCode: undefined,
    },
    { fallbackData: { results: vehicles, offset: 0, total_results: 0 } }
  );

  return (
    <>
      {products.length > 0 && (
        <Slider
          title={title}
          verticalSpacing={isMoreCarsExp ? (isMobile ? 1 : 2) : 3}
          titleHeadingSize={headingStyle}
          isForIframe={isForIframe || isInPLP}
        >
          {products.map((vehicle, idx) => (
            <div
              key={vehicle.id}
              data-testid="product-card"
              className={[
                classes.productItem,
                isMoreCarsExp ? 'moreCars' : '',
              ].join(' ')}
            >
              <ProductCard
                vehicle={vehicle}
                parentTitle={title}
                shouldShowPlaceholder={false}
                position={idx + 1}
                baseURL={baseURL}
                urlParams={urlParams}
                isForIframe={isForIframe}
                hidePrice={hidePrice}
                smallVersion={isMoreCarsExp}
                forceB2BPrice={forceB2BPrice}
              />
            </div>
          ))}
        </Slider>
      )}
      {cta && (
        <div className="container">
          <div className={classes.buttonWrapper}>
            <CTA data={cta.metadata} />
          </div>
        </div>
      )}
    </>
  );
};

export default ProductSlider;

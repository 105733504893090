import { makeStyles, Theme } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    paddingBottom: (props: Props) =>
      props.noPaddingBottom ? 0 : theme.spacing(7),
    paddingTop: (props: Props) => (props.noPaddingTop ? 0 : theme.spacing(6)),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: (props: Props) =>
        props.noPaddingBottom ? 0 : theme.spacing(9),
      paddingTop: (props: Props) => (props.noPaddingTop ? 0 : theme.spacing(8)),
    },
    [theme.breakpoints.up('md')]: {
      paddingBottom: (props: Props) =>
        props.noPaddingBottom ? 0 : theme.spacing(12),
      paddingTop: (props: Props) =>
        props.noPaddingTop ? 0 : theme.spacing(11),
    },
  },
}));

type Props = {
  noPaddingTop?: boolean;
  noPaddingBottom?: boolean;
  className?: string;
};

const SpacingBox: React.FunctionComponent<Props> = ({
  children,
  noPaddingTop,
  noPaddingBottom,
  className,
}) => {
  const classes = useStyles({ noPaddingTop, noPaddingBottom });

  return <div className={cn(classes.wrapper, className)}>{children}</div>;
};

export default SpacingBox;

export const plpSpecificObjects = {
  'en-US': {
    title: 'Localization Objects',
    type: 'localization',
    id: '617bc438223f7e0009405762',
    children: [
      {
        title: 'PLP',
        type: 'localization',
        id: '6182d0b77bf9860009e56b47',
      },
      {
        title: 'General',
        type: 'localization',
        id: '6182cda2cd5e3200093e93be',
      },
    ],
  },
  'de-DE': {
    title: 'Localization Objects',
    type: 'localization',
    id: '617bc4505e9acd000964d7f6',
    children: [
      {
        title: 'PLP',
        type: 'localization',
        id: '61925ab03ad3080008bfd7c2',
      },
      {
        title: 'General',
        type: 'localization',
        id: '619259de3ad3080008bfd7b8',
      },
    ],
  },
};

export const pdpSpecificObjects = {
  'en-US': {
    title: 'Localization Objects',
    type: 'localization',
    id: '617bc438223f7e0009405762',
    children: [
      {
        title: 'PDP',
        type: 'localization',
        id: '6182cf287bf9860009e56b3e',
      },
      {
        title: 'General',
        type: 'localization',
        id: '6182cda2cd5e3200093e93be',
      },
    ],
  },
  'de-DE': {
    title: 'Localization Objects',
    type: 'localization',
    id: '617bc4505e9acd000964d7f6',
    children: [
      {
        title: 'PDP',
        type: 'localization',
        id: '61925a353ad3080008bfd7bb',
      },
      {
        title: 'General',
        type: 'localization',
        id: '619259de3ad3080008bfd7b8',
      },
    ],
  },
};

export const checkoutSpecificObjects = {
  'en-US': {
    title: 'Localization Objects',
    type: 'localization',
    id: '617bc438223f7e0009405762',
    children: [
      {
        title: 'Checkout',
        type: 'localization',
        id: '6182d0eb7bf9860009e56b49',
      },
      {
        title: 'General',
        type: 'localization',
        id: '6182cda2cd5e3200093e93be',
      },
    ],
  },
  'de-DE': {
    title: 'Localization Objects',
    type: 'localization',
    id: '617bc4505e9acd000964d7f6',
    children: [
      {
        title: 'Checkout',
        type: 'localization',
        id: '619259c53ad3080008bfd7b6',
      },
      {
        title: 'General',
        type: 'localization',
        id: '619259de3ad3080008bfd7b8',
      },
    ],
  },
};

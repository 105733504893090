import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    paddingBottom: (props: Props) =>
      props.noPaddingBottom ? 0 : theme.spacing(4),
    paddingTop: (props: Props) => (props.noPaddingTop ? 0 : theme.spacing(3)),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: (props: Props) =>
        props.noPaddingBottom ? 0 : theme.spacing(5),
      paddingTop: (props: Props) => (props.noPaddingTop ? 0 : theme.spacing(4)),
    },
  },
}));
type Props = {
  noPaddingTop?: boolean;
  noPaddingBottom?: boolean;
};

const SmallSpacingBox: React.FunctionComponent<Props> = ({
  children,
  noPaddingTop,
  noPaddingBottom,
}) => {
  const classes = useStyles({ noPaddingTop, noPaddingBottom });

  return <div className={classes.wrapper}>{children}</div>;
};

export default SmallSpacingBox;
